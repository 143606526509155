import Page from "../page";
import Glide from '@glidejs/glide';
import axios from "axios";
import jsonpAdapter from "axios-jsonp";
import {Spinner} from 'spin.js';

export default class HomeIndex extends Page {
    constructor(component) {
        super(component);

        // DOM
        this.dom.html = document.querySelector('html');
        this.dom.logo = document.querySelector('.logo svg');
        this.dom.menuItems = this._findAll('.section-1 .menu .column-10 a');
        this.dom.containerInstagram = this._find('.section-7 .r-2');
        this.dom.overlay = document.querySelector('#overlay');
        this.dom.overlayContent = document.querySelector('#overlay .overlay-content');
        this.dom.sections = this._findAll('section');

        // VARS
        this.token = '5439599.6edbc9b.71f75d908ad84f908d9ded2808b1d042';
        this.userId = '6edbc9bacb1649ca9ce28dd5b321c7ca';
        this.numPictures = 3;
        this.hastag = "centvingtsept";
        this.isMobile = this.dom.html.classList.contains('mobile');

        // FUNC
        this._hideOverlay = this._hideOverlay.bind(this);
        this._setNextSlide = this._setNextSlide.bind(this);
    }

    init() {
        super.init();

        // INSTA
        this._loadInstagramFeed();

        // GLIDE
        this.glide_1_1 = new Glide('.glide-1-1', {
            type: 'carousel',
            focusAt: 'center',
            autoplay: false,
            startAt: 0,
            perView: 1,
            gap: 0,
            animationTimingFunc: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
            animationDuration: 800
        }).mount();

        this.glide_1_2 = new Glide('.glide-1-2', {
            type: 'carousel',
            focusAt: 'center',
            autoplay: false,
            startAt: 0,
            perView: 1,
            gap: 0,
            animationTimingFunc: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
            animationDuration: 800
        }).mount();

        this.glide_2_1 = new Glide('.glide-2-1', {
            type: 'carousel',
            focusAt: 'center',
            autoplay: false,
            startAt: 0,
            perView: 1,
            gap: 0,
            animationTimingFunc: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
            animationDuration: 800
        }).mount();

        this.glide_2_2 = new Glide('.glide-2-2', {
            type: 'carousel',
            focusAt: 'center',
            autoplay: false,
            startAt: 0,
            perView: 1,
            gap: 0,
            animationTimingFunc: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
            animationDuration: 800
        }).mount();

        this.glide_3_1 = new Glide('.glide-3-1', {
            type: 'carousel',
            focusAt: 'center',
            autoplay: false,
            startAt: 0,
            perView: 1,
            gap: 0,
            animationTimingFunc: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
            animationDuration: 800
        }).mount();

        this.glide_3_2 = new Glide('.glide-3-2', {
            type: 'carousel',
            focusAt: 'center',
            autoplay: false,
            startAt: 0,
            perView: 1,
            gap: 0,
            animationTimingFunc: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
            animationDuration: 800
        }).mount();

        this.glide_1 = new Glide('.glide-1', {
            type: 'carousel',
            focusAt: 'center',
            startAt: 0,
            perView: 3,
            gap: 60,
            breakpoints: {
                1023: {
                    perView: 1,
                    gap: 0
                }
            }
        });

        this.glide_1.on(['mount.after'], () => {
            this.dom.slides = this._findAll('.section-5 .r-2 .glide__slide a');
            for(let i = 0; i < this.dom.slides.length; i++) {
                let _s = this.dom.slides[i];
                _s.addEventListener('click', () => {
                    let _index = _s.dataset.sliderId;
                    this._showOverlay();
                    this._loadCurrentSlide(_index - 1);
                });
            }
        });

        this.glide_1.mount();

        this.glide_2 = new Glide('.glide-2', {
            type: 'carousel',
            focusAt: 'center',
            startAt: 0,
            perView: 3,
            gap: 60,
            breakpoints: {
                1023: {
                    perView: 1,
                    gap: 0,
                }
            }
        }).mount();

        /**
         * MOBILE
         */
        this.glide_mobile_1 = new Glide('.glide-mobile-1', {
            type: 'carousel',
            focusAt: 'center',
            startAt: 0,
            perView: 1,
            gap: 0,
        }).mount();

        this.glide_mobile_2 = new Glide('.glide-mobile-2', {
            type: 'carousel',
            focusAt: 'center',
            startAt: 0,
            perView: 1,
            gap: 0,
        }).mount();

        this.glide_mobile_3 = new Glide('.glide-mobile-3', {
            type: 'carousel',
            focusAt: 'center',
            startAt: 0,
            perView: 1,
            gap: 0,
        }).mount();

        // SPINNER
        let opts = {
            lines: 13, // The number of lines to draw
            length: 38, // The length of each line
            width: 17, // The line thickness
            radius: 45, // The radius of the inner circle
            scale: 0.2, // Scales overall size of the spinner
            corners: 1, // Corner roundness (0..1)
            color: '#000000', // CSS color or array of colors
            fadeColor: 'transparent', // CSS color or array of colors
            speed: 1, // Rounds per second
            rotate: 0, // The rotation offset
            animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
            direction: 1, // 1: clockwise, -1: counterclockwise
            zIndex: 2e9, // The z-index (defaults to 2000000000)
            className: 'spinner', // The CSS class to assign to the spinner
            top: '50%', // Top position relative to parent
            left: '50%', // Left position relative to parent
            shadow: '0 0 1px transparent', // Box-shadow for the lines
            position: 'absolute' // Element positioning
        };

        let target = document.querySelector('#container-spinner');
        this.spinner = new Spinner(opts).spin(target);

        // BIND
        this.bind();
    }

    bind() {
        super.bind();
        
        // MENU
        for(let i = 0; i < this.dom.menuItems.length; i++) {
            let _m = this.dom.menuItems[i];

            if(!this.isMobile) {
                _m.addEventListener('mouseenter', () => {
                    let _img = _m.dataset.image;
                    _m.classList.add('hover');

                    TweenLite.killTweensOf('.section-1 .background');

                    let _t = new TimelineMax();
                    _t.set(`.section-1 .images .${_img}`, {alpha: 0, y:20}, 0);
                    _t.to('.section-1 .background', 0.6, {alpha: 1, ease:Power4.easeOut});
                    _t.to('.section-1 .menu .column-10 a:not(.hover)', 0.6, {alpha: 0.5, ease:Power4.easeOut}, 0);
                    _t.to(`.section-1 .images .${_img}`, 0.6, {alpha: 1, y:0, ease:Power4.easeOut}, 0);
                });
                _m.addEventListener('mouseleave', () => {
                    let _img = _m.dataset.image;
                    _m.classList.remove('hover');

                    let _t = new TimelineMax();
                    _t.to('.section-1 .background', 1.5, {alpha: 0.5, delay: 0.1, ease:Power4.easeOut});
                    _t.to('.section-1 .menu .column-10 a', 0.6, {alpha: 1, ease:Power4.easeOut}, 0);
                    _t.to(`.section-1 .images .${_img}`, 0.6, {alpha: 0, y:-20, ease:Power4.easeOut}, 0);
                });
            }

            _m.addEventListener('click', () => {
                let _section = _m.dataset.section;
               TweenLite.to(window, 1, {scrollTo:{y: '.' + _section, autoKill: false}, ease:Power4.easeOut});
            });
        }

        // GLIDE
        this._find('[data-slider-id="1"] .prev').addEventListener('click', () => {
            this.glide_1_1.go('<');
            this.glide_1_2.go('<');
        });
        this._find('[data-slider-id="1"] .next').addEventListener('click', () => {
            this.glide_1_1.go('>');
            this.glide_1_2.go('>');
        });

        this._find('[data-slider-id="2"] .prev').addEventListener('click', () => {
            this.glide_2_1.go('<');
            this.glide_2_2.go('<');
        });
        this._find('[data-slider-id="2"] .next').addEventListener('click', () => {
            this.glide_2_1.go('>');
            this.glide_2_2.go('>');
        });

        this._find('[data-slider-id="3"] .prev').addEventListener('click', () => {
            this.glide_3_1.go('<');
            this.glide_3_2.go('<');
        });
        this._find('[data-slider-id="3"] .next').addEventListener('click', () => {
            this.glide_3_1.go('>');
            this.glide_3_2.go('>');
        });

        this._find('[data-slider-id="glide-1"] .prev').addEventListener('click', () => {
            this.glide_1.go('<');
            this.glide_1.go('<');
        });
        this._find('[data-slider-id="glide-1"] .next').addEventListener('click', () => {
            this.glide_1.go('>');
            this.glide_1.go('>');
        });

        this._find('[data-slider-id="glide-2"] .prev').addEventListener('click', () => {
            this.glide_2.go('<');
            this.glide_2.go('<');
        });
        this._find('[data-slider-id="glide-2"] .next').addEventListener('click', () => {
            this.glide_2.go('>');
            this.glide_2.go('>');
        });

        /**
         * MOBILE
         */
        this._find('[data-slider-id="mobile-1"] .prev').addEventListener('click', () => {
            this.glide_mobile_1.go('<');
            this.glide_mobile_1.go('<');
        });
        this._find('[data-slider-id="mobile-1"] .next').addEventListener('click', () => {
            this.glide_mobile_1.go('>');
            this.glide_mobile_1.go('>');
        });

        this._find('[data-slider-id="mobile-2"] .prev').addEventListener('click', () => {
            this.glide_mobile_2.go('<');
            this.glide_mobile_2.go('<');
        });
        this._find('[data-slider-id="mobile-2"] .next').addEventListener('click', () => {
            this.glide_mobile_2.go('>');
            this.glide_mobile_2.go('>');
        });

        this._find('[data-slider-id="mobile-3"] .prev').addEventListener('click', () => {
            this.glide_mobile_3.go('<');
            this.glide_mobile_3.go('<');
        });
        this._find('[data-slider-id="mobile-3"] .next').addEventListener('click', () => {
            this.glide_mobile_3.go('>');
            this.glide_mobile_3.go('>');
        });

        // READY
        this.ready();
    }

    scroll() {
        super.scroll();

        const windowScroll = window.scrollY;
        let halfWindowHeight = 310;

        if(this.isMobile)
            halfWindowHeight = 180;

        for(let i = 0; i < this.dom.sections.length; i++) {
            let _s = this.dom.sections[i];
            let _color = _s.dataset.headerColor;

            if (
                _s.offsetTop <= windowScroll + halfWindowHeight  &&
                _s.offsetTop + _s.offsetHeight > windowScroll + halfWindowHeight
            ) {
                if(!this.dom.logo.classList.contains(_color)) {
                    this.dom.logo.setAttribute('class', '');
                    this.dom.logo.classList.add(_color);
                }
            }
        }
    }

    _showOverlay() {
        let _t = new TimelineMax();
        _t.to('#overlay', 0.6, {autoAlpha: 1, ease:Power4.easeOut});
        _t.to('#overlay #container-spinner', 0.6, {autoAlpha: 1, ease:Power4.easeOut}, 0.2);
    }

    _hideOverlay() {
        let _t = new TimelineMax({
            onComplete: () => {
                TweenLite.set('#overlay #container-spinner', {autoAlpha: 0});
                TweenLite.set('#overlay .overlay-content', {autoAlpha: 0});
                this._resetOverlayContent();
            }
        });
        _t.to('#overlay', 0.3, {autoAlpha: 0, ease:Power4.easeOut});
    }

    _setNextSlide(e) {
        let _i = e.currentTarget.dataset.index;
        let _t = new TimelineMax({
            onComplete: () => {
                this._loadCurrentSlide(_i);
            }
        });
        _t.to('#overlay #container-spinner', 0.6, {autoAlpha: 1, ease:Power4.easeOut});
        _t.to('#overlay .overlay-content', 0.6, {autoAlpha: 0, ease:Power4.easeOut}, 0);
    }

    _loadCurrentSlide(i__index) {
        axios({
            method: 'get',
            url: AJAX_URL,
            params: {
                action: 'ajax_function_manager',
                fn: 'getTemplate',
                index: i__index
            },
            responseType: 'text'
        })
        .then((response) => {
            let _tpl = document.createElement('div');
            _tpl.innerHTML = response.data;
            this.dom.overlayContent.innerHTML = '';
            this.dom.overlayContent.appendChild(_tpl.firstChild);
            TweenLite.killTweensOf('#overlay #container-spinner');
            TweenLite.to(this.dom.overlayContent, 1, {autoAlpha: 1, ease:Power4.easeOut});
            TweenLite.to('#overlay #container-spinner', 0.3, {autoAlpha: 0, ease:Power4.easeOut});
            this._setOverlayContent();
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    _setOverlayContent() {
        document.querySelector('#overlay .close').addEventListener('click', this._hideOverlay);
        document.querySelector('#overlay .ui .prev').addEventListener('click', this._setNextSlide);
        document.querySelector('#overlay .ui .next').addEventListener('click', this._setNextSlide);
        this.dom.html.classList.add('locked');
    }

    _resetOverlayContent() {
        document.querySelector('#overlay .close').removeEventListener('click', this._hideOverlay);
        document.querySelector('#overlay .ui .prev').removeEventListener('click', this._setNextSlide);
        document.querySelector('#overlay .ui .next').removeEventListener('click', this._setNextSlide);
        this.dom.html.classList.remove('locked');
    }

    _loadInstagramFeed() {
        /*axios({
            url: 'https://api.instagram.com/v1/tags/' + this.hastag + '/media/recent?access_token=' + this.token,
            adapter: jsonpAdapter,
        }).then((res) => {
            console.log(res);
        }).catch(function (error) {
            console.log(error);
        });*/
    }
}