import {PAGE_EVENT} from "./pages/page";
import HomeIndex from "./pages/home";
import _ from "lodash";
import device from "current-device";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class App {
    constructor() {

        // DOM
        this.dom = {
            body: document.querySelector('body'),
            main: document.querySelector('#main'),
        };

        // VAR
        this.page = null;

        // INIT
        this.init();
    }

    /**
     * INIT
     */
    init() {
        this.bind();

        // BUILD
        this.build();
    }

    /**
     * BIND
     */
    bind() {

        // WINDOW
        window.addEventListener('scroll', _.throttle(this.scroll.bind(this), 100));
        //window.addEventListener('resize', _.throttle(this.resize.bind(this), 100));
    }

    /**
     * BUILD
     */
    build() {
        // PAGE
        if(this.dom.body.classList.contains('home'))
            this.page = new HomeIndex(document.querySelector('#main'));

        this.page.once(PAGE_EVENT.READY, this.pageIsReady.bind(this));
        this.page.init();
    }

    /**
     * PAGE IS READY
     * @param event
     */
    pageIsReady(event) {
        this.scroll();
        AOS.init({
            duration: 800,
        });
        this.page.show();

        TweenLite.to('.wrapper', 1.5, {autoAlpha: 1, delay:0.5, ease:Power4.easeOut});
    }

    /**
     * SCROLL
     */
    scroll() {
        if(this.page)
            this.page.scroll();
    }

    /**
     * RESIZE
     */
    resize() {
        if(this.page)
            this.page.resize();
    }
}

const __dcl = () => {
    new App();
};

document.addEventListener('DOMContentLoaded', __dcl);